import { Component, Inject } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    _deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) document: Document) {
    if (_deviceService.isMobile()) {
      document.location.href = 'https://soulpathreadings.com/mobile-redirect';
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevPaymentComponent } from '@pages/dev-payment/dev-payment.component';

const ROUTES: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main-container/main-container.module').then(m => m.MainContainerModule)
  },
  {
    path: 'dev-payment',
    component: DevPaymentComponent
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<h1>PAYMENT FORM</h1>
<form class="form">
    <div id="ccnumber"></div>
    <div id="ccexp"></div>
    <div id="cvv"></div>
    <div class="error-message">{{errorMessage}}</div>
    <button id="payButton" class="btn gold" (click)="makePayment(); $event.preventDefault()">Confirm</button>
</form>

<h2>TOKEN</h2>
<h3 (click)="copy()">COPY: {{token}}</h3>
import { Component, AfterViewInit } from '@angular/core';
declare var CollectJS: any;

@Component({
  selector: 'app-dev-payment',
  templateUrl: './dev-payment.component.html',
  styleUrls: ['./dev-payment.component.scss']
})
export class DevPaymentComponent implements AfterViewInit {
  public token = 'AAA';
  constructor() { }
  errorMessage = ''
  ngAfterViewInit(): void {
      CollectJS.configure({
        paymentType: 'cc',
        paymentSelector:"#payButton",
        variant: 'inline',
        invalidCss: {
          border: '1px solid #FF7070'
        },
        customCss: {
          'max-width': '415px',
          'width': '100%',
          'border-radius': '7px',
          'box-sizing': 'content-box',
          'background-color': '#464141',
          'display': 'flex',
          'padding': '12px',
          'margin-bottom': '16px',
          'justify-content': 'center',
          'align-items': 'center',
          'height': '46px',
          'margin-right': '8px',
          'flex': 'none',
          'color': '#F8E2D2',
          'font-size': '18px',
          'font-weight': '600',
          'line-height': '1.2',
        },
        validationCallback : (field: string, status: string, message: string) => {
          if (status) {
            this.errorMessage = '';
          } else {
            this.errorMessage = message;
          }
        },
        styleSniffer: true,
        callback: ({token}: any) => {
            console.log(token);
            this.token = token;
          },
        fields: {
          ccnumber: {
            placeholder: 'CC Number',
            selector: '#ccnumber'
          },
          ccexp: {
            placeholder: 'CC Expiration',
            selector: '#ccexp'
          },
          cvv: {
            placeholder: 'CVV',
            selector: '#cvv'
          }
        }
    })

  }


  makePayment() {
    CollectJS.startPaymentRequest();
  }

  copy() {
    navigator.clipboard.writeText(this.token);
  }
}
